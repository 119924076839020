.main-scene {
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;

  & > .left-edge,
  & > .right-edge {
    height: 100vh;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      height: 56px;
    }
  }

  & > .left-edge {
    position: fixed;
    left: 0;
    top: 0;
  }

  & > .right-edge {
    position: fixed;
    right: 0;
    top: 0;
  }

  & > .viewport {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    & > .scene {
      position: relative;
      width: fit-content;
      height: 100%;
      transition: transform 0.1s;

      img {
        height: 100%;
      }

      & > .item {
        cursor: pointer;
      }

      & > .store {
        width: fit-content;
        height: 100%;
      }

      & > .logo {
        position: absolute;
        bottom: 57%;
        left: 43%;
        height: 10%;
      }

      & > .eb {
        position: absolute;
        bottom: 8%;
        left: 8%;
        height: 64%;
      }

      & > .gaygay {
        position: absolute;
        bottom: 57.5%;
        left: 54.8%;
        height: 13%;
      }

      & > .guaigai {
        position: absolute;
        bottom: 8%;
        left: 21.5%;
        height: 22%;
      }

      & > .xiabing {
        position: absolute;
        bottom: 14%;
        left: 30%;
        height: 19%;
      }

      & > .kaorou {
        position: absolute;
        bottom: 10%;
        left: 37%;
        height: 20%;
      }

      & > .quackbox {
        position: absolute;
        bottom: 8%;
        left: 46%;
        height: 19%;
      }
    }
  }
}
