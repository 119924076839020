@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body{font-family:"Roboto","Noto Sans TC",sans-serif}
.header{position:fixed;top:0;left:0;z-index:10;width:100vw;background-color:#e8983b;padding:8px 24px;height:70px;box-sizing:border-box;display:flex;flex-direction:row;justify-content:space-between;align-items:center}.header img{height:100%;width:auto}.header>.menu{display:flex;flex-direction:row;align-items:center}.header>.menu>.menu-item{cursor:pointer;color:#fff;font-weight:bold}.header>.menu>.menu-item:not(:first-child){padding-left:36px}.header>.menu>.menu-item.sns{display:flex;flex-direction:row;align-items:center}.header>.menu>.menu-item.sns>.sns-icons:not(:first-child){padding-left:23px}.header>.menu>.menu-item.sns>.sns-icons>svg>path{fill:#fff}
.main-scene{width:100vw;overflow-y:scroll;overflow-x:hidden}.main-scene>.left-edge,.main-scene>.right-edge{height:100vh;width:100px;display:flex;align-items:center;justify-content:center}.main-scene>.left-edge>img,.main-scene>.right-edge>img{height:56px}.main-scene>.left-edge{position:fixed;left:0;top:0}.main-scene>.right-edge{position:fixed;right:0;top:0}.main-scene>.viewport{position:fixed;top:0;left:0;height:100vh;width:100vw}.main-scene>.viewport>.scene{position:relative;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:100%;transition:transform .1s}.main-scene>.viewport>.scene img{height:100%}.main-scene>.viewport>.scene>.item{cursor:pointer}.main-scene>.viewport>.scene>.store{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:100%}.main-scene>.viewport>.scene>.logo{position:absolute;bottom:57%;left:43%;height:10%}.main-scene>.viewport>.scene>.eb{position:absolute;bottom:8%;left:8%;height:64%}.main-scene>.viewport>.scene>.gaygay{position:absolute;bottom:57.5%;left:54.8%;height:13%}.main-scene>.viewport>.scene>.guaigai{position:absolute;bottom:8%;left:21.5%;height:22%}.main-scene>.viewport>.scene>.xiabing{position:absolute;bottom:14%;left:30%;height:19%}.main-scene>.viewport>.scene>.kaorou{position:absolute;bottom:10%;left:37%;height:20%}.main-scene>.viewport>.scene>.quackbox{position:absolute;bottom:8%;left:46%;height:19%}
